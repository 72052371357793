import moment from 'moment';

export const businessModelData = [
  'courseware',
  'directToLearner',
  'directToConsumerSubscription',
  'digitalSampleRep',
  'digitalSampleSelf',
  'digitalSampleMarketing',
];

export const userSearchCriteria = [
  {
    value: 'email',
    label: 'Email address',
    helperText: 'e.g. usersemail@uni.edu',
  },
  {
    value: 'phone',
    label: 'Phone number',
    helperText: 'Use format: +11234567890',
  },
  {
    value: 'userName',
    label: 'Username',
    helperText: 'Enter the exact username',
  },
  {
    value: 'partialUserName',
    label: 'Partial username',
    helperText: '5 to 10 characters only',
  },
  {
    value: 'name',
    label: 'First and last name',
    helperText: 'e.g. John Doe',
  },
  {
    value: 'identityId',
    label: 'Identity ID',
    helperText: 'Enter the Identity ID, e.g. ffffffff5c25b73086911976122a8acw',
  },
  {
    value: 'smsId',
    label: 'SMS ID',
    helperText: 'Enter the SMS ID, e.g. 94839809',
  },
  {
    value: 'lmsUserId',
    label: 'LMS User ID',
    helperText: 'Enter the LMS user Id, e.g. ff9eb1a0-8468-410a-9ce7-3485883b57e0',
  },
];

export const courseSearchCriteria = [
  {
    value: 'courseId',
    label: 'Course ID',
    helperText: 'Enter the Course ID, e.g. 6283db4f92bfe248e48247fe',
  },
  {
    value: 'courseTitle',
    label: 'Course title',
    helperText: 'Enter a course title, e.g. Biology 101',
  },
  {
    value: 'lmsCourseId',
    label: 'LMS Course Id',
    helperText: 'Enter the LMS Course ID, e.g. 7c49d55e72d19d1c5a5b1de66719675db535dea1',
  },
];

export const productSearchCriteria = [
  {
    value: 'id',
    label: 'Product ID, title or isbn',
    helperText: 'Enter the product ID, title or isbn',
  },
];

export const licenseSearchCriteria = [
  {
    value: 'id',
    label: 'License ID',
    helperText: 'Enter the license ID, e.g. 2be4e440-db83-441f-a224-a1b1c3b6fb1e',
  },
  {
    value: 'name',
    label: 'Owner name',
    helperText: 'Enter the first and last name of the owner',
  },
  {
    value: 'email',
    label: 'Owner email',
    helperText: "Enter the owner's email address",
  },
  {
    value: 'userName',
    label: 'Owner username',
    helperText: 'Enter the full username of the owner',
  },
  {
    value: 'ownerId',
    label: 'Owner ID',
    helperText: "Enter the owner's Identity ID, e.g. ffffffff60fefa024c554631f95c0d",
  },
  {
    value: 'orgId',
    label: 'Organization ID',
    helperText: 'e.g. urn:pearson:gps:organization:14bd165b-545f-4f6e-9f48-e46280ded4e2',
  },
  {
    value: 'tepOrderId',
    label: 'TEP Order ID',
    helperText: 'e.g. 11953737',
  },
  {
    value: 'tepContractId',
    label: 'TEP Contract ID',
    helperText: 'e.g. 8000145',
  },
  {
    value: 'productId',
    label: 'Product ID',
    helperText: 'e.g. A103000128126',
  },
  {
    value: 'orderId',
    label: 'Order ID',
    helperText: 'e.g. order98760',
  },
  {
    value: 'customerPoNumber',
    label: 'Customer PO Number',
    helperText: 'e.g. 741852963',
  },
];
export const orderSearchCriteria = [
  {
    value: 'orderId',
    label: 'Order ID',
    helperText: 'Enter the order ID or number, e.g. 8249040 (SMS) or WEBE70000001970274 (Passport)',
  },
];

export const rowsPerPageOptions = [5, 10, 25, 50, 100];

export const userPagination = {
  order: 'asc',
  orderBy: 'firstAndLastName',
  page: 0,
  rowsPerPage: rowsPerPageOptions[1],
};

export const coursePagination = {
  order: 'asc',
  orderBy: 'startDate',
  page: 0,
  rowsPerPage: rowsPerPageOptions[1],
};

export const productPagination = {
  order: 'asc',
  orderBy: 'name',
  page: 0,
  rowsPerPage: rowsPerPageOptions[1],
};

export const accessCodePagination = {
  order: 'asc',
  orderBy: 'ValidFromDate',
  page: 0,
  rowsPerPage: rowsPerPageOptions[1],
};

export const licensePagination = {
  order: 'desc',
  orderBy: 'status',
  page: 0,
  rowsPerPage: rowsPerPageOptions[1],
};

export const orderPagination = {
  order: 'asc',
  orderBy: 'orderID',
  page: 0,
  rowsPerPage: rowsPerPageOptions[1],
};

export const organizationPagination = {
  order: 'asc',
  orderBy: 'displayName',
  page: 0,
  rowsPerPage: rowsPerPageOptions[1],
};

export const createLicenseDefaults = {
  orgId: '',
  orgName: '',
  orgValid: false,
  organizationError: '',
  skuId: '',
  packageOptions: '',
  packageName: '',
  packageType: '',
  packageProductIds: [],
  packagePpId: '',
  packageId: '',
  productIds: [],
  productInput: '',
  productError: '',
  skuError: '',
  ownerId: '',
  ownerError: '',
  maxEntitlements: '',
  maxEntError: '',
  orderId: '',
  orderIdError: '',
  orderSystem: '',
  orderSystemError: '',
  acquisitionMethod: 'paid',
  startsAt: moment.utc(),
  endsAt: moment.utc().add(365, 'days'),
  tepOrderId: '',
  tepContractId: '',
  administrator: '',
  tepOfferId: '',
  tepOfferIds: [],
  tepOfferIderror: '',
  administratorIds: [],
  contact: '',
  contactIds: [],
  autoRenew: 'no',
  allowedOverage: '0',
  overageError: '',
  salesRep: '',
  salesRepError: '',
  customerPoNumber: '',
  offerId: '',
  duration: '365',
  error: '',
  validatedOwnerId: '',
};

// Entitlement Country List
export const enTitlementCountry = [
  {
    name: 'US - United States',
    value: 'US',
  },
  {
    name: 'AU - Australia',
    value: 'AU',
  },
  {
    name: 'CA - Canada',
    value: 'CA',
  },
  {
    name: 'GB - United Kingdom',
    value: 'GB',
  },
  {
    name: 'MY - Malaysia',
    value: 'MY',
  },
  {
    name: 'NZ - New Zealand',
    value: 'NZ',
  },
  {
    name: 'NL - BeNeLux',
    value: 'NL',
  },
  {
    name: 'SE - Nordics',
    value: 'SE',
  },
  {
    name: 'SG - Singapore',
    value: 'SG',
  },
  {
    name: 'PL - EU - Central and Eastern Europe',
    value: 'PL',
  },
  {
    name: 'CH - EU - CH - Switzerland',
    value: 'CH',
  },
  {
    name: 'DE - EU - DA - Austria, Germany',
    value: 'DE',
  },
  {
    name: 'IT - EU - Italy - Italy',
    value: 'IT',
  },
  {
    name: 'DK - EU-Nordics - Denmark, Finland, Iceland, Norway, Sweden',
    value: 'DK',
  },
  {
    name: 'FR - EU-South - Cyprus, France, Greece, Malta, Portugal, Spain',
    value: 'FR',
  },
  {
    name: 'IE - Ireland',
    value: 'IE',
  },
  {
    name: 'ZA - South Africa, Kenya, Botswana, Ghana',
    value: 'ZA',
  },
  {
    name: 'SA - Saudi Arabia',
    value: 'SA',
  },
  {
    name: 'QA - Qatar',
    value: 'QA',
  },
  {
    name: 'KW - Kuwait',
    value: 'KW',
  },
  {
    name: 'BH - Bahrain',
    value: 'BH',
  },
  {
    name: 'TR - Turkey',
    value: 'TR',
  },
  {
    name: 'EG - Egypt',
    value: 'EG',
  },
  {
    name: 'JO - Jordan',
    value: 'JO',
  },
  {
    name: 'OM - Oman',
    value: 'OM',
  },
  {
    name: 'LB - Lebanon',
    value: 'LB',
  },
  {
    name: 'MX - Mexico',
    value: 'MX',
  },
  {
    name: 'PR - Puerto Rico',
    value: 'PR',
  },
  {
    name: 'AG - Antigua and Barbuda',
    value: 'AG',
  },
  {
    name: 'BS - Bahamas',
    value: 'BS',
  },
  {
    name: 'BB - Barbados',
    value: 'BB',
  },
  {
    name: 'BZ - Belize',
    value: 'BZ',
  },
  {
    name: 'DM - Dominica',
    value: 'DM',
  },
  {
    name: 'GD - Grenada',
    value: 'GD',
  },
  {
    name: 'GY - Guyana',
    value: 'GY',
  },
  {
    name: 'HT - Haiti',
    value: 'HT',
  },
  {
    name: 'JM - Jamaica',
    value: 'JM',
  },
  {
    name: 'MS - Montserrat',
    value: 'MS',
  },
  {
    name: 'LC - Saint Lucia',
    value: 'LC',
  },
  {
    name: 'DO - Dominican Republic',
    value: 'DO',
  },
  {
    name: 'KN - Saint Kitts and Nevis',
    value: 'KN',
  },
  {
    name: 'VC - Saint Vincent and the Grenadines',
    value: 'VC',
  },
  {
    name: 'SR - Suriname',
    value: 'SR',
  },
  {
    name: 'TT - Trinidad and Tobago',
    value: 'TT',
  },
  {
    name: 'GT - Guatemala',
    value: 'GT',
  },
  {
    name: 'CO - Colombia',
    value: 'CO',
  },
  {
    name: 'PE - Peru',
    value: 'PE',
  },
  {
    name: 'AR - Argentina',
    value: 'AR',
  },
  {
    name: 'CL - Chile',
    value: 'CL',
  },
  {
    name: 'BR - Brazil',
    value: 'BR',
  },
  {
    name: 'PY - Paraguay',
    value: 'PY',
  },
  {
    name: 'UY - Uruguay',
    value: 'UY',
  },
  {
    name: 'CR - Costa Rica',
    value: 'CR',
  },
  {
    name: 'BO - Bolivia',
    value: 'BO',
  },
  {
    name: 'HN - Honduras',
    value: 'HN',
  },
  {
    name: 'SV - El Salvador',
    value: 'SV',
  },
  {
    name: 'PA - Panama',
    value: 'PA',
  },
  {
    name: 'EC - Ecuador',
    value: 'EC',
  },
  {
    name: 'AE - United Arab Emirates',
    value: 'AE',
  },
  {
    name: 'HK - Hong Kong',
    value: 'HK',
  },
  {
    name: 'MO - Macau',
    value: 'MO',
  },
  {
    name: 'ID - Indonesia',
    value: 'ID',
  },
  {
    name: 'PH - Philippines',
    value: 'PH',
  },
  {
    name: 'VN - Vietnam',
    value: 'VN',
  },
  {
    name: 'TH - Thailand',
    value: 'TH',
  },
  {
    name: 'TW - Taiwan',
    value: 'TW',
  },
  {
    name: 'JP - Japan',
    value: 'JP',
  },
  {
    name: 'KR - South Korea',
    value: 'KR',
  },
];
